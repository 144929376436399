<template>
  <section>
    <div class="header-page">
      <div class="container">
        <h1 data-aos="fade-up">Corporativo/RH</h1>
        <p class="subtitulo" data-aos="fade-down">
          Estimule o desenvolvimento e potencial de seus colaboradores.
        </p>
      </div>
    </div>

    <div class="container page">
      <div class="text" data-aos="fade-down">
        <p>
          Invista, treine e desenvolva seus colaboradores com uma ferramenta
          inteligente, engajadora e estratégica. Aumente o retorno em
          capacitações dos funcionários e estreite o relacionamento da empresa
          com a sua equipe.
        </p>
        <div class="valores-empresa">
          <div class="valor-item">
            <h2>Principais benefícios:</h2>
            <p>
              <br />• Valorização e desenvolvimento do seu time; <br />•
              Colaboradores atualizados com o que há de mais novo no mercado;
              <br />• Otimização da capacitação de novos funcionários; <br />•
              Maior eficiência se alinhando à tecnologia; <br />• Melhor opção
              de investimento para treinamento e capacitação de equipes.  
            </p>
          </div>
          <div class="valor-item">
            <h2>Ideal para:</h2>
            <p>
              <br />• Empresas de médio e grande porte; <br />•
              Empresas/Negócios de capacitação de colaboradores e gestores
              profissionais; <br />• Empresas que buscam auxiliar na formação e
              valorização do profissional.
            </p>
          </div>
        </div>
      </div>
      <div class="imagem">
        <img
          src="@/assets/img/corporativo-rh.png"
          alt="sistema"
          class="foto-page"
          data-aos="fade-left"
        />
        <img
          src="@/assets/icons/detalhe_01.svg"
          class="detalhe"
          data-aos="fade-right"
        />
      </div>
    </div>
    <div class="container segmentos">
      <Carrosel :cards="cards" :segmentos="true">
        <div>
          <h2>Conheça <b>outros segmentos</b></h2>
          <p class="sub">
            Conheça mais sobre outros segmentos e descubra como podemos atuar na
            sua empresa.
          </p>
        </div>
      </Carrosel>
    </div>
  </section>
</template>

<script>
import Carrosel from "@/components/Carrosel";

export default {
  name: "RH",
  data() {
    return {
      cards: [
        {
          path: "icons/icon-distribuidores.svg",
          title: "Distribuidores",
          date: "",
          text: "A oferta digital mais criativa para o seu portfólio.",
          link: "/distribuidores",
        },
        {
          path: "icons/icon-editoras.svg",
          title: "Editoras",
          date: "",
          text: "O AVA com a cara da sua editora. Conectada, interativa e 100% digital.",
          link: "/editoras",
        },
        {
          path: "icons/icon-sistema.svg",
          title: "Sistema de ensino",
          date: "",
          text: "A solução mais prática para fidelizar alunos, professores e gestores ao seu sistema de ensino.",
          link: "/sistema-de-ensino",
        },
        {
          path: "icons/icon-prefeitura.svg",
          title: "Prefeituras e Governos",
          date: "",
          text: "Sem essa de versão light. A mesma tecnologia para rede pública e privada.",
          link: "/prefeituras-e-governos",
        },
        {
          path: "icons/icon-empresas.svg",
          title: "Empresas e Promoções",
          date: "",
          text: "Marketing inteligente que fideliza e aumenta as suas vendas.",
          link: "/empresas-e-promocoes",
        },
        {
          path: "icons/icon-cursos.svg",
          title: "Cursos e Ensino Técnico",
          date: "",
          text: "Qualidade, conteúdo e o engajamento que a sua instituição precisa em um só lugar!",
          link: "/cursos-e-ensino-tecnico",
        },
        {
          path: "icons/icon-universidade.svg",
          title: "Universidade",
          date: "",
          text: "Qualidade, conteúdo e o engajamento que a sua instituição precisa em um só lugar!",
          link: "/universidades",
        },
      ],
    };
  },
  components: {
    Carrosel,
  },
};
</script>
